
<template>
  <v-container class="pa-10">
    <v-data-table :headers="headers" :items="products" sort-by="id" class="elevation-1">
      <template v-slot:top >
        <v-toolbar flat color="white" class="mb-5">
          <v-toolbar-title>My Group Products</v-toolbar-title>
            <v-spacer></v-spacer>
          <div class="mt-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                filled
                rounded
                dense
                hide-details
                
              ></v-text-field>
            </div>
          <v-divider class="mx-4" inset vertical></v-divider>
        
          <v-dialog v-model="dialog" max-width="1200px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark to="/supportdashboard/pages/addgroupproduct"  class="mt-5">
                <v-icon color="white">mdi-plus</v-icon>Create Group
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-form>
                        <v-row>
                          <v-col cols="12" sm="6" md="3">
                            <v-select
                              v-model="editedItem.category_id"
                              label="category"
                              outlined
                              dense
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-select
                              v-model="editedItem.sub_category_id"
                              label="Sub category"
                              outlined
                              dense
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-select
                              v-model="editedItem.sub_sub_category_id"
                              label="Sub Sub category"
                              outlined
                              dense
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field v-model="editedItem.title" label="Title" outlined dense></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              v-model="editedItem.description"
                              label="Product Description"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              v-model="editedItem.key_features"
                              label="Product key features"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              v-model="editedItem.price"
                              label="Product Price"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              v-model="editedItem.point"
                              label="Product Point"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-row>
                            <v-col cols="12" sm="6" md="3">
                              <v-text-field
                                v-model="editedItem.discount_type"
                                label="Discount Type"
                                outlined
                                dense
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="3">
                              <v-text-field
                                v-model="editedItem.amount"
                                label="Discount Amount "
                                outlined
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                              <v-datetime-picker
                                label="Discount Start Date"
                                v-model="editedItem.start_date"
                                outlined
                                dense
                              ></v-datetime-picker>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                              <v-datetime-picker
                                label="Discount End Date"
                                v-model="editedItem.end_date"
                              ></v-datetime-picker>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="6" md="3">
                              <v-text-field
                                v-model="editedItem.products_ids"
                                label="Group product ids"
                                outlined
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                              <v-text-field
                                v-model="editedItem.flashsellname"
                                label="Name of your Flash Sell"
                                outlined
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                              <v-datetime-picker
                                label="Sell Start Date"
                                v-model="editedItem.start_date"
                              ></v-datetime-picker>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                              <v-datetime-picker
                                label="Sell End Date"
                                v-model="editedItem.end_date"
                              ></v-datetime-picker>
                            </v-col>
                          </v-row>
                          <v-col cols="12" sm="6" md="3">
                            <template>
                              <v-file-input
                                v-model="editedItem.image"
                                color="deep-purple accent-4"
                                counter
                                label="Upload Your Images"
                                multiple
                                outlined
                                :show-size="1000"
                              >
                                <template v-slot:selection="{ index, text }">
                                  <v-chip
                                    v-if="index < 2"
                                    color="deep-purple accent-4"
                                    dark
                                    label
                                    small
                                  >{{ text }}</v-chip>

                                  <span
                                    v-else-if="index === 2"
                                    class="overline grey--text text--darken-3 mx-2"
                                  >+{{ files.length - 2 }} File(s)</span>
                                </template>
                              </v-file-input>
                            </template>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-checkbox
                            v-model="editedItem.active"
                            label="Check if your product is active"
                            @change="$v.checkbox.$touch()"
                            @blur="$v.checkbox.$touch()"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="editedItem.properties"
                            label="Check if your product property is private"
                            @change="$v.checkbox.$touch()"
                            @blur="$v.checkbox.$touch()"
                          ></v-checkbox>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="saveEdit">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        <v-icon small @click="viewSpecification(item)">mdi-view-list</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Group Product ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Title", value: "title" },
      { text: "Flashsale Name", value: "flashsellname" },
      { text: "Start Date", value: "startdate" },
      { text: "End Date", value: "enddate" },
      { text: "Product Ids", value: "products_ids" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    products: [],
    contact: {},
    editedIndex: -1,
    editedItem: {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      active: true,
      products_ids: "",
      title: "",
      flashsellname: "",
      image: "",
      key_features: "",
      properties: true,
      point: "",
      price: "",
      discount_type: "",
      amount: "",
      start_date: "",
      end_date: "",
      timestamp: "",
    },
    defaultItem: {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      active: true,
      products_ids: "",
      title: "",
      flashsellname: "",
      image: "",
      key_features: "",
      properties: true,
      point: "",
      price: "",
      discount_type: "",
      amount: "",
      start_date: "",
      end_date: "",
      timestamp: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    initialize() {
      axios.get("product/group/detail/").then((response) => {
     
        this.products = response.data.data;
      });
    },

    editItem(item) {
      this.editedItem = item;
      this.dialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios.post(`product/group/delete/${item.id}/`).then((response) => {
          this.products = this.products.filter(
            (contact) => contact.id !== item.id
          );
        });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    saveEdit() {
      axios
        .post(
          `product/modify_group_product/${this.editedItem.id}/`,
          this.editedItem
        )
        .then((response) => {
          this.dialog = false;
        });
    },
    viewSpecification(item) {
  
      this.$router.push({
        path: "/supportdashboard/pages/groupspecification",
        query: { id: item.id },
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>


